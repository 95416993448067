import { createContext, useEffect, useState } from "react";
import debounce from "debounce";

export const SCREENTYPE = {
    DESKTOP: "desktop",
    MOBILE: "mobile",
};

export const LayoutContext = createContext({screenType: SCREENTYPE.DESKTOP});

const getScreenType = () => {
    if (typeof window !== "undefined") {
        const height = (window || {}).innerHeight;
        const width = (window || {}).innerWidth;
        const valid = width !== 0 && height !== 0;
        return valid && width < 768 ? SCREENTYPE.MOBILE : SCREENTYPE.DESKTOP;
    }
    return SCREENTYPE.DESKTOP;
};

const useLayout = () => {
    const [screenType, setScreenType] = useState(getScreenType());

    useEffect(() => {
        const onResize = () => {
            setScreenType(getScreenType());
        };

        const debounceHandleResize = debounce(onResize, 500, {
            immediate: false,
        });

        window.addEventListener("resize", debounceHandleResize);

        // Handling standby on iPad using Javascript
        window.addEventListener("focus", debounceHandleResize);
        // Could be detected with an event:blur when the user quit the website

        return () => {
            window.removeEventListener("resize", debounceHandleResize);
            window.removeEventListener("focus", debounceHandleResize);
        };
    }, []);

    return { screenType };
};

export default useLayout;
