import "../styles/globals.css";
import "@/styles/global.global.scss";
import "@/ui/loading/loading.global.scss";

import React, { useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { usePathname } from "next/navigation";
import { DefaultSeo } from "next-seo";

import { sendPageviewEvent } from "@telequebec/tq-googleanalytics";
import Script from "next/script";
import version from "@/configs/version.json";

import { DidomiSDK } from "@didomi/react";
import { initMia } from "@/ui/pub/magniteOutstream";
import { AppProps } from "next/app";

import useLayout, { LayoutContext } from "@/hooks/useLayout";
import useCategories, { CategoryContext } from "@/hooks/useCategories";

const env = String(process?.env?.NODE_ENV || "production").toLowerCase();
if (env !== "production" && env !== "staging") {
    console.log("version =>", version);
}

const MyApp = ({ Component, pageProps }: AppProps) => {
    const router = useRouter();
    const pathname = usePathname();
    const canonicalUrl = `${process.env.NEXT_PUBLIC_SITE_BASE_URL}${pathname}`;

    const [isLanding, setIsLanding] = useState(true);
    const { screenType } = useLayout();
    const categories = useCategories();

    useEffect(() => {
        import("@telequebec/tq-headerfooter");
    }, []);

    useEffect(() => {
        if (isLanding) {
            sendPageviewEvent({
                page: {
                    brand: "Cuisinez",
                    version: version?.versionShort,
                },
            });

            setIsLanding(false);
        }
    }, [isLanding]);

    useEffect(() => {
        const handleRouteChange = () => {
            window.madops?.reset();

            initMia();

            sendPageviewEvent({
                page: { brand: "Cuisinez" },
            });
        };

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <DefaultSeo
                additionalMetaTags={[
                    {
                        name: "version",
                        content: process?.env?.NEXT_PUBLIC_APP_VERSION || version?.versionShort,
                    },
                    {
                        name: "env",
                        content: env,
                    },
                ]}
                canonical={canonicalUrl}
                defaultTitle="Cuisinez | T&#xE9;l&#xE9;-Qu&#xE9;bec"
                description="Toutes nos &#xE9;missions web et t&#xE9;l&#xE9;"
                facebook={{
                    appId: "268879007082272",
                }}
                openGraph={{
                    locale: "fr_CA",
                    siteName: "Cuisinez",
                    type: "website",
                    url: process.env.NEXT_PUBLIC_SITE_BASE_URL,
                }}
                titleTemplate="%s | Cuisinez | T&#xE9;l&#xE9;-Qu&#xE9;bec"
            />
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
                
                <meta charSet="utf-8" />

                <link type="text/plain" rel="author" href="/humans.txt" />

                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <meta
                    name="google-site-verification"
                    content="bw5Bff7-P0bgyoEXjuzPFXupGSg1RMgVbf-rdkb-MN8"
                />
                <meta name="p:domain_verify" content="b9997ec5348d256701960d8e347605e1" />

                <link rel="stylesheet" href="https://use.typekit.net/rdh6cfr.css" />
                <link rel="stylesheet" href="https://static.web-prod.telequebec.tv/fonts/GuillonTQc/300.css" />
                <link rel="stylesheet" href="https://static.web-prod.telequebec.tv/fonts/GuillonTQc/400.css" />
                <link rel="stylesheet" href="https://static.web-prod.telequebec.tv/fonts/GuillonTQc/600.css" />

                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#009a74" />
                <meta name="apple-mobile-web-app-title" content="Cuisinez" />
                <meta name="application-name" content="Cuisinez" />
                <meta name="msapplication-TileColor" content="#009a74" />

                <meta name="theme-color" content="#009a74" />
                <meta name="theme-color" content="#009a74" media="(prefers-color-scheme: light)" />
                <meta name="theme-color" content="#005b5f" media="(prefers-color-scheme: dark)" />

                <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />

                <script defer src="//unpkg.com/@ungap/global-this" />

                <script
                    defer
                    type="text/javascript"
                    src="https://rdc.m32.media/m32pixel.min.js"
                ></script>

                <script
                    defer
                    type="text/javascript"
                    src="https://rdc.m32.media/madops.min.js"
                ></script>
            </Head>
            <Script
                id="didomi-onready"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `window.didomiOnReady = window.didomiOnReady || [];
                             window.dataLayer = window.dataLayer || []`,
                }}
            />
            <Script
                id="gtm-script"
                strategy="afterInteractive"
                type="didomi/javascript"
                data-vendor="c:googleana-4TXnJigR"
                data-purposes="measure_content_performance,cookies"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');`,
                }}
            />
            <DidomiSDK
                apiKey="eb32d57f-00a0-4b08-86ba-0db4e8d4845d"
                iabVersion={1} // If you want to support the TCF v1∏, don't forget to change this value,
                //even if you selected the TCF v2 in the console.
                //This parameter will load the correct stub in the React Component
                noticeId="UcZbUty7" // If you want to target the notice by ID and not by domain
                gdprAppliesGlobally={true}
                sdkPath="https://sdk.privacy-center.org/"
                embedTCFStub={true}
            />

            <noscript>Vous devez activer le javascript pour consulter ce site.</noscript>

            <LayoutContext.Provider value={{ screenType }}>
                <CategoryContext.Provider value={categories}>
                    <Component {...pageProps} />
                </CategoryContext.Provider>
            </LayoutContext.Provider>
            
            <tq-footer />
        </>
    );
};

export default MyApp;
