import URLS from "../../configs/urls"

export const MagniteOutstreamScript = () => {
    return (
        <script defer src={URLS.miaPlayer} data-publisher-id="66188" id="miaPlayerScript"></script>
    )
}
export const initMia = () => {

    const magnite = document.getElementById("magnite_outstream")
    if (magnite){
        magnite.innerHTML = "";  
        if (window.hasOwnProperty("initMIA")) { 
            window.initMIA(); 
        }            
    }
}