import { Categorie, CategorieCompact } from "@/types/categorie";

export const formatCategories = (categories: Categorie[] = []): Categorie[] => {
    const recetteCategory = {
        description: "",
        id: 0,
        nom: "Recettes",
        ordre: 1,
        parentId: null,
        slug: "recettes",
        type: "categorie",
        typeCategorie: "recette",
    } as Categorie;

    const formatedCategories = [
        recetteCategory,
        ...categories?.map((cat: Categorie) =>
            cat.parentId === null && cat.typeCategorie === "recette"
                ? {
                      ...cat,
                      parentId: 0,
                  }
                : cat,
        ),
    ];

    const sortedCategories = formatedCategories
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .sort((a, b) => (a.nom < b.nom ? 1 : -1))
        .sort((a, b) => (a.ordre > b.ordre ? 1 : -1));

    return sortedCategories;
};

// Get Category from Category ID
export const getCategoryFromId = (
    categoryId: string | number | null,
    allCategories: Categorie[],
) => {
    if (!categoryId) {
        return null;
    }

    const category = allCategories.find((aCat) => aCat.id === categoryId);
    if (!category) {
        return null;
    }

    return category;
};

const getHierachy = (category: Categorie, allCategories: Categorie[]): Categorie[] => {
    if (!allCategories) {
        return [];
    }

    const parentId = category.parentId || null;
    const parent = getCategoryFromId(parentId, allCategories);
    if (parentId && !parent) {
        return [];
    }

    return parent ? [...getHierachy(parent, allCategories), { ...category }] : [{ ...category }];
};

export const findBestCategoriesByParentOrder = (
    categories: CategorieCompact[],
    allCategories: Categorie[],
) => {
    if (!categories || !Array.isArray(allCategories)) {
        return [];
    }
    const matchingCategory =
        categories === null
            ? null
            : allCategories?.filter((cat) => categories.find((cCat) => cCat.id === cat.id));

    const matchingHierachy = matchingCategory
        ?.map((mCat) => getHierachy(mCat, allCategories))
        .filter((x) => x.length > 0);

    const orderedMatchingHierachy = matchingHierachy?.sort((a, b) => {
        const ordreA = a
            .map((aa: { ordre: number }) => (aa.ordre ? aa.ordre.toString().padStart(5, "0") : 1))
            .join("_");
        const ordreB = b
            .map((bb: { ordre: number }) => (bb.ordre ? bb.ordre.toString().padStart(5, "0") : 2))
            .join("_");
        return ordreA > ordreB ? 1 : -1;
    });

    if (!orderedMatchingHierachy) return [];

    return orderedMatchingHierachy[0] || [];
};

const getHierachyTopCategory = (categoryId: string | number, allCategories: Categorie[]) => {
    const matchingCategory = allCategories.filter((cat) => categoryId == cat.id);
    if (!matchingCategory || matchingCategory.length === 0) {
        return null;
    }

    const matchingCategorySingleFirst = matchingCategory.slice(0, 1);
    let matchingCategorySingle = matchingCategorySingleFirst[0];

    if (matchingCategorySingle.parentId) {
        const matchingCategorySingleLevel1 = getHierachyTopCategory(
            matchingCategorySingle.parentId,
            allCategories,
        );
        if (matchingCategorySingleLevel1 !== null) {
            matchingCategorySingle = matchingCategorySingleLevel1;
        }
    }

    return matchingCategorySingle;
};

// Get Hierachy Categories from Top By CategoryId
export const findCategoriesTopHierachyByCategoryId = (
    categoryId: string | number,
    allCategories: Categorie[],
) => {
    if (!categoryId) {
        return null;
    }

    const hierachyTopCategory = getHierachyTopCategory(categoryId, allCategories);
    if (!hierachyTopCategory) {
        return null;
    }

    const matchingHierachy = [hierachyTopCategory]
        .map((mCat) => getHierachy(mCat, allCategories))
        .filter((x) => x.length > 0);

    const orderedMatchingHierachy = matchingHierachy.sort((a, b) => {
        const ordreCategoryA = a
            .map((aa: { ordre: number }) => (aa.ordre ? aa.ordre.toString().padStart(5, "0") : 1))
            .join("_");
        const ordreCategoryB = b
            .map((bb: { ordre: number }) => (bb.ordre ? bb.ordre.toString().padStart(5, "0") : 2))
            .join("_");
        return ordreCategoryA > ordreCategoryB ? 1 : -1;
    });

    return orderedMatchingHierachy || [];
};

// Get Parent Category Id
export const getCategoryParentId = (categoryId: string | number, allCategories: Categorie[]) => {
    let parentId = null;
    allCategories.forEach((category) => {
        if (category.id === categoryId) {
            parentId = category.parentId;
        }
    });

    if (!parentId) {
        return null;
    }

    return parentId;
};

// Get Hierachy Child Categories from CategoryId
export const findChildsCategoriesHierachy = (
    categoryId: string | number,
    allCategories: Categorie[],
    categoryParentId = null,
) => {
    if (!categoryId) {
        return null;
    }

    let parentId = categoryParentId;
    if (categoryParentId === null) {
        const getParentId = getCategoryParentId(categoryId, allCategories);
        if (!getParentId) {
            return null;
        }
        parentId = getParentId;
    }

    const parentChildsCategories = allCategories.filter((aCat) => aCat.parentId === parentId);
    if (!parentChildsCategories || parentChildsCategories.length === 0) {
        return [];
    }

    const orderedMatchingHierachy = parentChildsCategories.sort((a, b) => {
        const orderCategoryA = [a]
            .map((aa) => (aa.ordre ? aa.ordre.toString().padStart(5, "0") : 1))
            .join("_");
        const orderCategoryB = [b]
            .map((bb) => (bb.ordre ? bb.ordre.toString().padStart(5, "0") : 2))
            .join("_");
        return orderCategoryA > orderCategoryB ? 1 : -1;
    });

    return orderedMatchingHierachy || [];
};

export const getSortedCategoriesByOrder = (categories: Categorie[]) => {
    if (!categories || categories.length === 0) {
        return [];
    }

    return [...categories].sort((a, b) => a.ordre - b.ordre);
};
